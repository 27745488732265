@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,900;1,400;1,500;1,600;1,700&display=swap');
*,
*:after,
*:before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}
select {
  -webkit-appearance: none;
  background-image: url('./assets/arrow.svg');
  background-repeat: no-repeat;
  background-position: right;
  background-position-x: 95%;
  cursor: pointer;
  background-size: 10px;
}
select:active,
select:focus {
  background-image: url('./assets/arrow_up.svg');
}
.tempNav ul {
  display: flex;
  list-style: none;
}
.tempNav ul li {
  padding: 15px;
}

a {
  text-decoration: none;
  color: #14527f;
  transition: all ease 0.3s;
  cursor: pointer;
}
a:focus,
a:hover {
  color: #68b6ec;
}

.error {
  color: orangered;
}
@media only screen and (max-width: 600px) {
  .welcomeMessage {
    display: none;
  }
}

.copyButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.copyButton span {
  display: none;
}
.copyButton span.active {
  display: inline;
}

.sideBySide {
  display: flex;
  align-items: center;
}
.sideBySide p {
  margin-bottom: 0 !important;
}

.viewButton {
  color: #120e87;
}

:root {
  --toastify-text-color-light: #000000;
}